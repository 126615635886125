import React from "react";
import { Popover, Row, Col, Tag, Button } from 'antd';
import '../../master-data/style.css';

const InfoWindowContent = ({ details, userLat, userLng }) => (
    <div style={{ cursor: 'pointer', borderRadius: 12, width: 300 }}>
        <Row span={24} style={{ borderBottom: 10 }}>
            <Col span={12}>
                <span className='textStyles-small' style={{ fontSize: 14, fontWeight: 600, color: 'gray' }}>Site Information</span>
            </Col>
            <Col span={12}>
                <Tag
                    color={
                        details?.status === 'ACTIVE' ? 'green' :
                            details?.status === 'INACTIVE' ? 'red' :
                                details?.status === 'Under Construction' ? 'orange' :
                                    undefined
                    }
                    style={{ float: 'right', borderRadius: 5 }}
                >
                    <span className='textStyles-small'><strong>
                        {details?.status === 'ACTIVE' ?
                            'Active' : details?.status === 'INACTIVE' ? 'Inactive' : details?.status === 'Under Construction' ? 'UC' : ''}
                    </strong></span>
                </Tag>
            </Col>
        </Row>
        <br />
        <span className='textStyles-small' style={{ marginBottom: 10, display: 'block' }}><strong>Site ID:</strong> {details?.siteId}</span>
        <span className='textStyles-small' style={{ marginBottom: 10, display: 'block' }}><strong>Site Name:</strong> {details?.siteName}</span>
        <span className='textStyles-small' style={{ marginBottom: 10, display: 'block' }}><strong>Region:</strong> {details?.region}</span>
        <span className='textStyles-small' style={{ marginBottom: 10, display: 'block' }}><strong>Depot:</strong> {details?.depot}</span>
        <span className='textStyles-small' style={{ marginBottom: 10, display: 'block' }}><strong>Depot Officer:</strong> {details?.depotOfficer}</span>
        <span className='textStyles-small' style={{ marginBottom: 10, display: 'block' }}><strong>Site Owner:</strong> {details?.siteOwnerName}</span>
        <span className='textStyles-small' style={{ marginBottom: 10, display: 'block' }}><strong>Site Type:</strong> {details?.siteType}</span>
        <span className='textStyles-small' style={{ marginBottom: 10, display: 'block' }}><strong>Latitude:</strong> {details?.latitude}</span>
        <span className='textStyles-small' style={{ marginBottom: 10, display: 'block' }}><strong>Longitude:</strong> {details?.longitude}</span>
        <span className='textStyles-small' style={{ marginBottom: 10, display: 'block' }}><strong>Address:</strong> {details?.address}</span>
        <Row span={24} style={{ marginTop: 20 }}>
            <Col span={12}>
                <Button
                    style={{ backgroundColor: '#F286034F', borderColor: '#F286034F', borderRadius: 5 }}
                    onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&origin=${userLat},${userLng}&destination=${details.latitude},${details.longitude}`, '_blank')}
                >
                    <span className='textStyles-small' style={{ color: '#F28603' }}>Get Direction</span>
                </Button>
            </Col>
            <Col span={12}>
                <Button style={{ backgroundColor: '#F28603', borderColor: '#F28603', float: 'right', borderRadius: 5 }}
                    onClick={() => window.location.href = `/site/new/${details.id}`}>
                    <span className='textStyles-small' style={{ color: 'white' }}>View Site Details</span>
                </Button>
            </Col>
        </Row>
    </div>
);

export default InfoWindowContent;
