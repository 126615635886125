import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { siteViewNewhierarchy, siteViewNew } from '../../api/api.config';
import axios from "axios";
import { Loader } from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import InfoWindowContent from "./map/detailCard";
import { Row, Col, message, Divider } from "antd";
import svgGenarator from "./map/svgGen";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const MapGoogleUI = ({ mapData, userLat, userLng, iconCategoryFunction, iconDataSet }) => {
  const mapInstance = useRef(null);
  const markersRef = useRef([]);
  const [staticalData, setStaticalData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [iconCategory, setIconCategory] = useState(0);
  const [zoom, setZoom] = useState(7);
  const [iconData, setIconData] = useState(0);

  useEffect(() => {
    getMapPreference();
    fetchStatisticsFunction();
  }, []);

  const fetchStatisticsFunction = async () => {
    try {
      const response = await axios.get(siteViewNew + 'api/v1/statistics');
      setStaticalData(response?.data?.results);
    } catch (err) {
    } finally {
      console.log('Problem With the request');
    }
  }

  const getMapPreference = async () => {
    try {
      const response = await axios.get(siteViewNewhierarchy + 'api/v1/auth/map-preference', {
        headers: {
          "userId": localStorage.getItem("userId")
        }
      });
      let iconCat = switchDataMap.find(data => data.preferenceType === response?.data?.results?.preferenceType);
      setIconCategory(iconCat?.value ? iconCat?.value : 0);
      iconCategoryFunction(iconCat?.value ? iconCat?.value : 0);
    } catch (error) {
      message.error(<span className="textStyles-small">Error fetching map preference</span>, 5);
    }
  }

  const saveMapPreference = async (data) => {
    let requestBody = {
      preferenceType: data,
    }
    try {
      const response = await axios.post(siteViewNewhierarchy + 'api/v1/auth/map-preference', requestBody, {
        headers: {
          "userId": localStorage.getItem("userId")
        }
      });
      message.success(<span className="textStyles-small">Map preference saved successfully</span>, 2);
    } catch (error) {
      message.error(<span className="textStyles-small">Error saving map preference</span>, 2);
    }
  }

  const clearMarkers = () => {
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];
  };

    const staticDataMap = [
    { label: "Region", value: staticalData?.regionCount, highlighted: true, level: 'province' },
    { label: "Depot", value: staticalData?.depotCount, highlighted: false, level: 'district' },
    { label: "Site", value: staticalData?.siteCount, highlighted: false, level: 'city' },
  ];

  const switchDataMap = [
    { label: "Default", value: 0, highlighted: false, eNum: 0, preferenceType: "NONE"},
    { label: "Operator", value: 3, highlighted: false, eNum: 1, preferenceType: "OPERATOR" },
    { label: "Tower Category", value: 2, highlighted: true, eNum: 3, preferenceType: "TOWER_CATEGORY"},
    { label: "Power Solutions", value: 1, highlighted: false, eNum: 2, preferenceType: "POWER_SOLUTIONS"},
    { label: "Tower Owner", value: 4, highlighted: false, eNum: 4, preferenceType: "TOWER_OWNER" },
  ];

  const PlusMinusComponent = () => {
    return (
      <div style={{
        backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '5px', width: '45px', display: 'flex',
        flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '5px'
      }}>
        <div style={{ marginTop: 2, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
          onClick={handleZoomIn}>
          <PlusOutlined style={{ fontSize: '20px', color: '#595959' }} />
        </div>
        <Divider style={{ margin: '5px 0' }} />
        <div style={{ marginTop: 2, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
          onClick={handleZoomOut}>
          <MinusOutlined style={{ fontSize: '20px', color: '#595959' }} />
        </div>
      </div>
    );
  };

  const handleZoomIn = () => {
    setZoom(zoom + 1);
  };

  const handleZoomOut = () => {
    setZoom(zoom - 1);
  };


  const createCustomPin = (position, iconType) => {
    const svg = svgGenarator(iconType, iconCategory, position, iconData);
    return svg;
  };

  useEffect(() => {
    setIconData(iconDataSet);
    const loader = new Loader({ apiKey: "AIzaSyC7QjauaBLV9W__koQgy65PIuxYbcSPpfg" });
    loader.load().then((google) => {
      mapInstance.current = new google.maps.Map(document.getElementById("map"), {
        center: { lat: userLat || 7.8731, lng: userLng || 80.7718 },
        zoom: zoom,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: true,
        zoomControl: false,
        gestureHandling: "greedy",
      });

      const fullscreenControlButton = document.querySelector('.gm-fullscreen-control');
      if (fullscreenControlButton) {
        fullscreenControlButton.style.borderRadius = '5px';
        fullscreenControlButton.style.overflow = 'hidden';
      }

      const zoomControlButtons = document.querySelectorAll('.gm-bundled-control .gmnoprint');
      zoomControlButtons.forEach(button => {
        button.style.borderRadius = '12px';
        button.style.overflow = 'hidden';
      });
  

      if (!loading) {
        clearMarkers();
        const infoWindow = new google.maps.InfoWindow({
          content: "",
          disableAutoPan: true,
        });
        const createMarker = (position, iconType) => {
          const svg = createCustomPin(position, iconType);
          const marker = new google.maps.Marker({
            position: {
              lat: parseFloat(position?.latitude),
              lng: parseFloat(position?.longitude),
            },
            icon: {
              url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg),
            },
          });
          marker.addListener("click", () => {
            const infoWindowContent = document.createElement("div");
            ReactDOM.render(
              <InfoWindowContent details={position} userLat={userLat} userLng={userLng} />,
              infoWindowContent
            );
            infoWindow.setOptions({ pixelOffset: new google.maps.Size(0, -30) });
            infoWindow.setContent(infoWindowContent);
            infoWindow.open(mapInstance?.current, marker);
          });
          return marker;
        };
        markersRef.current = mapData?.map((position) => createMarker(position, iconCategory));
        new MarkerClusterer({
          markers: markersRef?.current,
          map: mapInstance?.current,
        });
      }
    });
  }, [userLat, userLng, loading, mapData, iconDataSet ]);

  const updateMarkerColors = (newIconType) => {
    markersRef.current.forEach(marker => {
      const updatedSvg = createCustomPin(mapData[markersRef?.current?.indexOf(marker)], newIconType);
      marker.setIcon({
        url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(updatedSvg),
      });
    });
  };

  useEffect(() => {
    if (!loading) {
      updateMarkerColors(iconCategory);
    }
  }, [iconCategory, loading]);

  useEffect(() => {
    if (mapInstance.current) {
      mapInstance.current.setZoom(zoom);
    }
  }, [zoom]);

    return (
    <div style={{ position: "relative", height: "100vh", width: "100%" }}>
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '8px',
          borderRadius: '5px',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          fontSize: '14px',
          zIndex: 10,
          width: '319px',
          display: 'flex',
          flexDirection: 'column', // Change to column to stack rows
          justifyContent: 'center',
          alignItems: 'center',
          height: 'auto', // Adjust height dynamically
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {staticDataMap.map((data, index) => (
            <Col span={8} key={index}>
              <div
                style={{
                  backgroundColor: '#C4C4C452',
                  borderColor: '#C4C4C452',
                  borderRadius: 5,
                  width: '96px',
                  height: '29px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0 10px',
                }}
              >
                <span className="textStyles-small" style={{ fontWeight: 500, fontSize: 12, color: 'black' }}>{data?.label}</span>
                <span className="textStyles-small" style={{ fontWeight: 500, fontSize: 12, color: 'black' }}>{data?.value}</span>
              </div>
            </Col>
          ))}
        </Row>

        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
            width: '100%',
          }}
        >
          {switchDataMap.map((data, index) => (
            <Col
              key={index}
              style={{
                flex: '1 1 auto',
                maxWidth: '20%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: iconCategory === data?.value ? 'black' : '#C4C4C452',
                  borderColor: iconCategory === data?.value ? 'black' : '#C4C4C452',
                  borderRadius: 5,
                  width: '57px',
                  height: '29px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0 10px',
                  margin: '0 5px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  saveMapPreference(data?.eNum);
                  setIconCategory(data?.value);
                  iconCategoryFunction(data?.value);
                }}
              >
                <span className="textStyles-small" style={{
                  fontWeight: 500, fontSize: 10,
                  color: iconCategory === data?.value ? 'white' : 'black'
                }}>{data?.label}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <div style={{
          position: 'absolute',
          top: 'calc(100vh - 36vh)',
          right: '15px',
          padding: '8px',
          borderRadius: '5px',
          fontSize: '14px',
          zIndex: 20,
          width: '45px',
          display: 'flex',
          flexDirection: 'column', 
          justifyContent: 'center',
          alignItems: 'center',
          height: 'auto',
        }}>
          <PlusMinusComponent />
        </div>

      <div id="map" style={{ width: "100%", height: "100vh" }} />
    </div>
  );
};

export default MapGoogleUI;
