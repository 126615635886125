import React, { Component, Fragment } from 'react';
import { siteViewNew, siteViewNewhierarchy } from '../../api/api.config';
import {
  getFilterDetails,
  getAllRegionRelatedData,
  getAllSitesListByID,
  getMapQueryTemplateByUser,
  getIconData
} from "../../api/index";
import $ from 'jquery';
import { Row, Col, Button, Tag, Divider, Select, Form, Badge, Modal, Collapse, Spin, Table, message, Tabs, Input } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import '../master-data/style.css';
import MapGoogleUI from './mapGoogle';
import axios from 'axios';
const { Option } = Select;
const { Panel } = Collapse;

const data = [];

class MapWithCustomPins extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      sitesList: [],
      centerLat: 7.3,
      centerLng: 80.6,
      zoom: 7,
      clusters: data,
      level: 'province',
      previousClusters: [],
      popupInfo: null,
      staticalData: [],
      allSiteList: [],
      siteOwnerList: [],
      towerCategoryList: [],
      towerTypeList: [],
      siteTypeList: [],
      allRegions: [],
      allDepots: [],
      openFilter: false,
      loadFullSiteList: [],
      loading: false,
      filterBtnClick: false,
      showTable: false,
      showNav: true,
      selectedRegionID: [],
      selectedDepotID: [],
      mapQueryFilter: {
        siteIds: [],
        regionIds: [],
        depotIds: [],
        status: null,
        siteOwnerIds: [],
        siteTypeIds: [],
        towerCategoryIds: [],
        towerTypeIds: []
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        totalPages: 0,
        lastSeenId: 0,
        hasNextPage: true
      },
      isActiveFilter: true,
      selectedMapRegionCluster: {
        regionId: null,
        depots: []
      },
      selectedMapDepotCluster: {
        depotId: null,
        sites: []
      },
      iconCategory: 0,
      showReferences: false,
      newSiteList: [],
      userLocation: {
        lat: 0,
        lng: 0
      },
      singleView: false,
      fetchMapDataArray: [],
      savedTemplates: [],
      iconData: [],
      currentDepots: [],
      searchSavedTemplates: [],
    };
  }

  success = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    this.setState({
      userLocation: {
        lat: latitude,
        lng: longitude
      }
    });
  }

  error = () => {
    message.error("Unable to retrieve your location");
  }

  async componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.success, this.error);
    } else {
      message.error(<span className="textStyles-small">Geolocation is not supported by this browser</span>);
    }
    try {
      const transportedData = localStorage.getItem('mapQuery');
      if (transportedData) {
        const data = JSON.parse(transportedData);
        this.setState({
          mapQueryFilter: {
            siteIds: data?.siteIds || [],
            regionIds: data?.regionIds || [],
            depotIds: data?.depotIds || [],
            status: data?.status || null,
            siteOwnerIds: data?.siteOwnerIds || [],
            siteTypeIds: data?.siteTypeIds || [],
            towerCategoryIds: data?.towerCategoryIds || [],
            towerTypeIds: data?.towerTypeIds || []
          },
        });
        this.fetchFirstCall(data);
      } else {
        let requestBody = {
          siteIds: [],
          regionIds: [],
          depotIds: [],
          status: null,
          siteOwnerIds: [],
          siteTypeIds: [],
          towerCategoryIds: [],
          towerTypeIds: [],
        }
        this.fetchFirstCall(requestBody);
      }
    } catch (error) {
      message.error(<span className="textStyles-small">Error fetching map data</span>);
    }

    await Promise.all([
      this.fetchNewSiteList(),
      this.fetchTowerCategoryList(),
      this.fetchTowerTypeList(),
      this.fetchSiteTypeList(),
      this.fetchSiteOwnerList(),
      this.fetchRegionList(),
      this.fetchDepotList(),
      this.fetchAllSiteListSet(),
      this.loadAllSavedTemplates(),
      this.fetchIconDataFromBackend()
    ]);
  }

  fetchIconDataFromBackend = async () => {
    const response = await getIconData();
    if (response.success) {
      this.setState({
        iconData: response.data,
      });
    }
  }

  switchDataMapIDbyEnum = (preferenceType) => {
    if (preferenceType === 'NONE') {
      return 0;
    } else if (preferenceType === 'OPERATOR') {
      return 3;
    } else if (preferenceType === 'TOWER_CATEGORY') {
      return 2;
    } else if (preferenceType === 'POWER_SOLUTIONS') {
      return 1;
    } else if (preferenceType === 'TOWER_OWNER') {
      return 4;
    }
  }

    loadAllSavedTemplates = async () => {
      try {
        const response = await getMapQueryTemplateByUser(localStorage.getItem("userId"));
        this.setState({
          savedTemplates: response?.data,
          searchSavedTemplates: response?.data
        });
      } catch (error) {
        message.error("Error fetching saved templates", 5);
      }
    }

    filterTemplatesByName = (value) => {
      const lowercasedValue = value.toLowerCase();
      const filteredItems = this.state.savedTemplates.filter((item) => item.templateName.toLowerCase().includes(lowercasedValue));
      this.setState({
        searchSavedTemplates: filteredItems
      });
    };

  filterBtnClick = (values) => {
    this.fetchSiteBySiteIDAllData(values);
  }

  fetchSiteBySiteIDAllData = async (values) => {
    let requestBody = {
      siteIds: values?.siteIds ? values.siteIds : [],
      regionIds: values?.region ? values.region : [],
      depotIds: values?.depot ? values.depot : [],
      status: values?.status ? values.status : null,
      siteOwnerIds: values?.siteOwner ? values.siteOwner : [],
      siteTypeIds: values?.siteType ? values.siteType : [],
      towerCategoryIds: values?.towerCategory ? values.towerCategory : [],
      towerTypeIds: values?.towerType ? values.towerType : [],
    }
    this.setState({
      loading: true
    })
    const response = await axios.post(siteViewNew + 'api/v1/site-view/all',
      requestBody,
      {
        headers: {
          "userId": localStorage.getItem("userId")
        }
      }
    );
    if (response?.data?.results) {
      let depotColors = response.data.results.reduce((acc, site) => {
        if (!acc.some(item => item.depotName === site.depot)) {
            acc.push({
                depotName: site.depot,
                color: site.depotColorCode
            });
        }
        return acc;
    }, []);
      this.setState({
        fetchMapDataArray: response.data.results ? response.data.results : [],
        loading: false,
        openFilter: false,
        currentDepots: depotColors
      });
    }
    localStorage.setItem("mapQuery", JSON.stringify({
      siteIds: [],
      regionIds: [],
      depotIds: [],
      status: null,
      siteOwnerIds: [],
      siteTypeIds: [],
      towerCategoryIds: [],
      towerTypeIds: []
    }));
  }

  fetchFirstCall = async (requestBody) => {
    this.setState({
      loading: true
    })
    const response = await axios.post(siteViewNew + 'api/v1/site-view/all',
      requestBody,
      {
        headers: {
          "userId": localStorage.getItem("userId")
        }
      }
    );
    if (response?.data?.results) {
      let depotColors = response.data.results.reduce((acc, site) => {
          if (!acc.some(item => item.depotName === site.depot)) {
              acc.push({
                  depotName: site.depot,
                  color: site.depotColorCode
              });
          }
          return acc;
      }, []);
      this.setState({
        fetchMapDataArray: response.data.results ? response.data.results : [],
        loading: false,
        openFilter: false,
        currentDepots: depotColors
      });
      localStorage.setItem("mapQuery", JSON.stringify({
        siteIds: [],
        regionIds: [],
        depotIds: [],
        status: null,
        siteOwnerIds: [],
        siteTypeIds: [],
        towerCategoryIds: [],
        towerTypeIds: []
      }));
    }
  }


  fetchNewSiteList = async () => {
    try {
      const response = await axios.get(siteViewNew + 'api/v1/site-view/list', {
        headers: {
          "userId": localStorage.getItem("userId")
        }
      });
      this.setState({
        newSiteList: response?.data?.results
      })
    } catch (error) {
      console.error("Error fetching site list:", error);
    }
  }

  all = () => {
    $("body").toggleClass("enlarge-menu");
    $('.button-menu-mobile').on('click', function (event) {
      event.preventDefault();
    });
  }

  fetchAllSiteListSet = async () => {
    var responseAPI = await getAllSitesListByID();
    if (responseAPI.success) {
      this.setState({
        loadFullSiteList: responseAPI?.data,
      });
    }
  }

  fetchSiteOwnerList = async () => {
    const response = await getFilterDetails('siteOwner');
    console.log(response);
    if (response.success) {
      this.setState({
        siteOwnerList: response.data,
      });
    }
  };

  fetchTowerCategoryList = async () => {
    const response = await getFilterDetails('towerCategory');
    console.log(response);
    if (response.success) {
      this.setState({
        towerCategoryList: response.data,
      });
    }
  };

  fetchTowerTypeList = async () => {
    const response = await getFilterDetails('towerType');
    console.log(response);
    if (response.success) {
      this.setState({
        towerTypeList: response.data,
      });
    }
  };

  fetchSiteTypeList = async () => {
    const response = await getFilterDetails('siteType');
    console.log(response);
    if (response.success) {
      this.setState({
        siteTypeList: response.data,
      });
    }
  };

  fetchRegionList = async () => {
    const response = await getAllRegionRelatedData('permission-regions');
    if (response.success) {
      this.setState({
        allRegions: response?.data?.results ? response.data.results : [],
      });
    }
  }

  fetchDepotList = async () => {
    const response = await getAllRegionRelatedData('permission-depots');
    if (response.success) {
      this.setState({
        allDepots: response?.data?.results ? response?.data?.results : [],
      });
    }
  }

  getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            centerLat: position.coords.latitude,
            centerLng: position.coords.longitude,
            zoom: 12,
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  tableColumns = [
    {
      title: <span className="textStyles-small">Site ID</span>,
      dataIndex: 'siteId',
      key: 'siteId',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Site Name</span>,
      dataIndex: 'siteName',
      key: 'siteName',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Region</span>,
      dataIndex: 'region',
      key: 'region',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Depot</span>,
      dataIndex: 'depot',
      key: 'depot',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Status</span>,
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        let color = '#1FAD4B36';
        let fontColor = '#1FAD4B';
        let displayText = 'Active';
        if (text.toLowerCase() === 'inactive' || text.toLowerCase() === 'deactivated' || text.toLowerCase() === 'removed') {
          color = '#C4C4C452';
          fontColor = '#979797';
          displayText = 'Removed';
        } else if (text.toLowerCase() === 'live' || text.toLowerCase() === 'active') {
          color = '#1FAD4B36';
          fontColor = '#1FAD4B';
          displayText = 'Live';
        }
        return (
          <Tag color={color} style={{ padding: 5, borderRadius: 5 }} key={text} className="textStyles-small">
            <span style={{ color: fontColor, fontWeight: 550, fontSize: '12px' }} className="textStyles-small">
              {displayText}
            </span>
          </Tag>
        );
      }
    },
    {
      title: <span className="textStyles-small">Tower Category</span>,
      dataIndex: 'towerCategoryName',
      key: 'towerCategoryName',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Site Type</span>,
      dataIndex: 'siteType',
      key: 'siteType',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Depot Officer</span>,
      dataIndex: 'depotOfficer',
      key: 'depotOfficer',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Site Owner</span>,
      dataIndex: 'siteOwnerName',
      key: 'siteOwnerName',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Contact No</span>,
      dataIndex: 'contactNo',
      key: 'contactNo',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
  ];

  render() {
    const switchDataMap = [
      { label: "Default", value: 0, highlighted: false, eNum: 0 },
      { label: "Operator", value: 3, highlighted: false, eNum: 1 },
      { label: "Tower Category", value: 2, highlighted: true, eNum: 3 },
      { label: "Power Solutions", value: 1, highlighted: false, eNum: 2 },
      { label: "Tower Owner", value: 4, highlighted: false, eNum: 4 },
    ];

    const towerOwner = ['DAP', 'ESLL', 'Hutch', 'Lanka Bell', 'Mobitel', 'SLT', 'MOD', 'Other'];

    const buttonStyles = (isActive) => ({
      borderColor: isActive ? "#F28603" : "white",
      backgroundColor: isActive ? "#F28603" : "white",
      borderRadius: "50px",
      width: "50px",
      height: "50px",
      marginLeft: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    });

    const svgFillColor = (isActive) => (isActive ? "white" : "black");

    const iconCategoryChangeFunction = (catID) => {
      this.setState({
        iconCategory: catID,
      });
    }

    const getModifiedSvg = (svgString, width, height, fillColor) => {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
      const svgElement = svgDoc.documentElement;
      
      svgElement.setAttribute('width', width);
      svgElement.setAttribute('height', height);
    
      const paths = svgElement.querySelectorAll('[fill="black"]');
      paths.forEach(path => {
        path.setAttribute('fill', fillColor);
      });
      const serializer = new XMLSerializer();
      return serializer.serializeToString(svgElement);
    };

    const tabItems = [
      {
        key: '1',
        label: <span className='textStyles-small' style={{ fontSize: 12 }}>Basic Filter</span>,
        children: <Fragment>
                    <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(values) => {
              this.filterBtnClick(values);
              this.setState({
                isActiveFilter: true,
                allSiteList: [],
                pagination: {
                  current: 1,
                  pageSize: 10,
                  total: 0,
                  totalPages: 0,
                  lastSeenId: 0,
                  hasNextPage: true
                },
                mapQueryFilter: {
                  regionIds: values.region || [],
                  depotIds: values.depot || [],
                  siteIds: values.siteIds || [],
                  status: values.status || null,
                  operator: values.operator || null,
                  towerCategory: values.towerCategory || null,
                  powerSolution: values.powerSolution || null,
                },
                level: 'province',
                zoom: 7,
                centerLat: 7.3,
                centerLng: 80.6,
              });
            }}
          >
            <Collapse ghost={true} expandIconPosition="end" style={{ backgroundColor: 'white' }}>
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Region</span>} key="2">
                <Form.Item name="region">
                  <Select
                    placeholder="Select Region"
                    bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    className='borderedSelect'
                    mode='multiple'
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      this.setState({
                        selectedRegionID: value
                      })
                    }
                    }
                  >
                    {this.state.allRegions.map((region) => (
                      <Option className="textStyles-small" key={region?.id} value={region?.id}>
                        {region.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Depot</span>} key="3">
                <Form.Item name="depot">
                  <Select
                    placeholder="Select Depot"
                    bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    showSearch
                    mode='multiple'
                    className='borderedSelect'
                    onChange={(value) => {
                      this.setState({
                        selectedDepotID: value
                      })
                    }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      this.state.selectedRegionID.length === 0 ? (
                        this.state.allDepots.map((depot) => (
                          <Option className="textStyles-small" key={depot.id} value={depot.id}>
                            {depot.name}
                          </Option>
                        ))
                      ) : (
                        this.state.allDepots
                          .filter((depot) => this.state.selectedRegionID?.includes(depot?.parentRegionId))
                          .map((depot) => (
                            <Option className="textStyles-small" key={depot.id} value={depot.id}>
                              {depot.name}
                            </Option>
                          ))
                      )
                    }
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Site</span>} key="10">
                <Form.Item name="siteIds">
                  <Select placeholder="Select Status" bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    showSearch
                    mode='multiple'
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    className='borderedSelect'>

                    {
                      this.state.selectedRegionID.length === 0 && this.state.selectedDepotID.length === 0 ? (
                        this.state.newSiteList.map((site) => (
                          <Option className="textStyles-small" key={site.id} value={site.id}>
                            {site.siteName}
                          </Option>
                        ))
                      ) : null
                    }

                    {
                      this.state.selectedRegionID.length === 0 && this.state.selectedDepotID.length !== 0 ? (
                        this.state.newSiteList
                          .filter((site) => this.state.selectedDepotID.includes(site.depotId))
                          .map((site) => (
                            <Option className="textStyles-small" key={site.id} value={site.id}>
                              {site.siteName}
                            </Option>
                          ))
                      ) : null
                    }

                    {
                      this.state.selectedRegionID.length !== 0 && this.state.selectedDepotID.length === 0 ? (
                        this.state.newSiteList
                          .filter((site) => this.state.selectedRegionID.includes(site.regionId))
                          .map((site) => (
                            <Option className="textStyles-small" key={site.id} value={site.id}>
                              {site.siteName}
                            </Option>
                          ))
                      ) : null
                    }
                    {
                      this.state.selectedRegionID.length !== 0 && this.state.selectedDepotID.length !== 0 ? (
                        this.state.newSiteList
                          .filter((site) => this.state.selectedDepotID.includes(site.depotId))
                          .map((site) => (
                            <Option className="textStyles-small" key={site.id} value={site.id}>
                              {site.siteName}
                            </Option>
                          ))
                      ) : null
                    }
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Status</span>} key="4">
                <Form.Item name="status">
                  <Select placeholder="Select Status" bordered={false} defaultValue={null}
                    style={{ width: "100%", height: 'auto' }}
                    className='borderedSelect'>
                    <Option className="textStyles-small" value={null}><Badge color="blue" style={{ marginRight: 10 }} />All</Option>
                    <Option className="textStyles-small" value="Live"><Badge color="green" style={{ marginRight: 10 }} />Live</Option>
                    <Option className="textStyles-small" value="Removed"><Badge color="red" style={{ marginRight: 10 }} />Removed</Option>
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Site Owner</span>} key="5">
                <Form.Item name="siteOwner">
                  <Select
                    placeholder="Select Site Owner"
                    bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    mode='multiple'
                    className='borderedSelect'
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.siteOwnerList.map((siteOwner) => (
                      <Option className="textStyles-small" key={siteOwner.id} value={siteOwner.id}>
                        {siteOwner.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Site Type</span>} key="6">
                <Form.Item name="siteType">
                  <Select
                    placeholder="Select Site Type"
                    bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    showSearch
                    mode='multiple'
                    className='borderedSelect'
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.siteTypeList.map((siteType) => (
                      <Option className="textStyles-small" key={siteType.id} value={siteType.id}>
                        {siteType.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Tower Category</span>} key="7">
                <Form.Item name="towerCategory">
                  <Select
                    placeholder="Select Tower Category"
                    bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    showSearch
                    mode='multiple'
                    className='borderedSelect'
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.towerCategoryList.map((towerCategory) => (
                      <Option className="textStyles-small" key={towerCategory.id} value={towerCategory.id}>
                        {towerCategory.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Tower Type</span>} key="8">
                <Form.Item name="towerType">
                  <Select
                    placeholder="Select Tower Type"
                    bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    showSearch
                    mode='multiple'
                    className='borderedSelect'
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.towerTypeList.map((towerType) => (
                      <Option className="textStyles-small" key={towerType.id} value={towerType.id}>
                        {towerType.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Panel>
              <Button
                type="button"
                class="btn btn-soft-primary btn-sm"
                htmlType="submit"
                style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', width: '100%', height: '34px', marginTop: 10 }}
              >
                <span className="textStyles-small" style={{ color: 'white', fontSize: 13 }}>Filter</span>
              </Button>

              <Button
                type="button"
                class="btn btn-soft-secondary btn-sm"
                htmlType='reset'
                style={{ borderColor: '#CCCCCC', backgroundColor: '#CCCCCC', borderRadius: '5px', width: '100%', height: '34px', marginTop: 10 }}
              >
                <span className="textStyles-small" style={{ color: 'black', fontSize: 13 }}>Reset</span>
              </Button>
            </Collapse>
          </Form>
        </Fragment>
      },
      {
        key: '2',
        label: <span className='textStyles-small' style={{ fontSize: 12 }}>Templates</span>,
        children: <Fragment>
          <Input
            placeholder="Search templates..."
            onChange={(e) => this.filterTemplatesByName(e.target.value)}
            style={{ marginBottom: 20, width: '100%', height: 34 }}
            className="borderedSelect"
          />
          <div style={{ overflowY: 'auto', height: '60vh' }} className='custom-scroll'>
            {
              this.state.searchSavedTemplates.map((template, index) => (
                <div key={index} style={{ padding: 10, border: '#f4f4f4', borderRadius: 12, cursor: 'pointer', width: '100%', marginTop: 5, backgroundColor: '#f4f4f4'}}>
                  <Row span={24}>
                    <Col span={20}>
                      <span className="textStyles-small">{template?.templateName.replace(/_/g, ' ')}</span>
                    </Col>
                    <Col span={4}>
                      <Button
                        onClick={() => {
                          this.setState({
                            isActiveFilter: true,
                            allSiteList: [],
                            pagination: {
                              current: 1,
                              pageSize: 10,
                              total: 0,
                              totalPages: 0,
                              lastSeenId: 0,
                              hasNextPage: true
                            }
                          });
                          this.filterBtnClick(JSON.parse(template?.parameters), 0, true);
                        }}
                        shape="circle"
                        style={{
                          marginLeft: 10,
                          backgroundColor: '#BA183A',
                          borderColor: '#BA183A',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer'
                        }}
                      >
                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16 8.00023L18.3642 5.63609M5.63631 18.364L8.00026 16M17.6566 12H21M3 12H6.34315M12 6.34342L12 3M12 21L12 17.6569M8.00023 8.00023L5.63609 5.63609M18.364 18.364L16 16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))
            }
          </div>
        </Fragment>
      },
    ]
      

    return (
      <div>
        {
          this.state.showNav ? (
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row">
                    <div className="col">
                      <span className="textStyles-small" style={{ fontSize: '14px', fontWeight: 'bold' }}>Map View</span>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a className='textStyles-small'>Site</a></li>
                        <li className="breadcrumb-item"><a className='textStyles-small'>Map View</a></li>
                      </ol>
                    </div>
                    <div className="col-auto align-self-center">
                      <Button
                        type="button"
                        className="btn btn-soft-primary btn-sm"
                        style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', width: '100px', height: '34px', marginLeft: 5 }}
                        onClick={() => this.setState({ openFilter: true })}
                        icon={<FilterOutlined style={{ color: 'white' }} />}
                      >
                        <span className="textStyles-small" style={{ color: 'white', fontSize: 13 }}>Filter</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }

        <Spin spinning={this.state.loading} size='large'>
          <Col lg={24}>
            <div style={{
              position: 'relative',
              height: this.state.showTable ? 'calc(100vh - 200px)' : this.state.showNav ? '80vh' : 'calc(100vh - 50px)',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              overflow: 'hidden'
            }}>

              {
                this.state.iconCategory !== 6 ? (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "65px",
                      left: "5px",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                      zIndex: 10,
                      width: "fit-content",
                    }}
                  >
                    <Button
                      type="button"
                      className="btn btn-soft-primary btn-sm"
                      style={buttonStyles(this.state.showReferences)}
                      onClick={() => {
                        this.setState((prevState) => ({ showReferences: !prevState.showReferences }));
                        window.scrollTo({
                          top: this.state.showReferences ? 0 : document.body.scrollHeight,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M14.8061 6.08133L10.2328 10.6507L8.72076 9.15733L11.4808 6.384H5.08076C4.66053 6.384 4.24442 6.46677 3.85617 6.62759C3.46793 6.7884 3.11517 7.02411 2.81802 7.32126C2.52087 7.61841 2.28516 7.97117 2.12435 8.35941C1.96353 8.74765 1.88076 9.16377 1.88076 9.584C1.88076 10.0042 1.96353 10.4203 2.12435 10.8086C2.28516 11.1968 2.52087 11.5496 2.81802 11.8467C3.11517 12.1439 3.46793 12.3796 3.85617 12.5404C4.24442 12.7012 4.66053 12.784 5.08076 12.784H6.26743V14.9173H5.09409C3.72493 14.8523 2.43336 14.2627 1.48739 13.2707C0.541414 12.2788 0.0136719 10.9607 0.0136719 9.59C0.0136719 8.2193 0.541414 6.90122 1.48739 5.90927C2.43336 4.91732 3.72493 4.32767 5.09409 4.26267H11.4941L8.71943 1.50667L10.2328 0L14.8061 4.576V6.08133ZM22.1594 32H9.35943L8.29276 30.9333V13.8827L9.35943 12.816H22.1594L23.2261 13.8827V30.9333L22.1594 32ZM10.4261 29.8667H21.0928V14.9493H10.4261V29.8667ZM17.8928 2.136H30.6928L31.7594 3.20267V20.2507L30.6928 21.3173H25.3594V19.184H29.6261V4.26667H18.9594V10.6493H16.8261V3.20133L17.8928 2.13467V2.136ZM18.9594 17.0507H12.5594V19.184H18.9594V17.0507ZM12.5594 21.3173H18.9594V23.4507H12.5594V21.3173ZM18.9594 25.584H12.5594V27.7173H18.9594V25.584ZM21.0928 6.384H27.4928V8.51733H21.0928V6.384ZM27.4928 14.9173H25.3594V17.0507H27.4928V14.9173ZM23.0421 10.6827V10.6507H27.4928V12.784H25.1434L23.0421 10.6827Z"
                          fill={svgFillColor(this.state.showReferences)} />
                      </svg>

                    </Button>
                  </div>
                ) : null
              }

              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "5px",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  zIndex: 10,
                  width: "fit-content",
                }}
              >
                <Button
                  type="button"
                  className="btn btn-soft-primary btn-sm"
                  style={buttonStyles(this.state.showTable)}
                  onClick={() => {
                    this.setState((prevState) => ({ showTable: !prevState.showTable }));
                    window.scrollTo({
                      top: this.state.showTable ? 0 : document.body.scrollHeight,
                      behavior: "smooth",
                    });
                  }}
                >
                  <svg
                    width="30"
                    height="29"
                    viewBox="0 0 40 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 5.45455C0 2.44209 2.44209 0 5.45455 0H13.5245C16.0274 0 18.2091 1.70344 18.8162 4.13162L19.6015 7.27274H22.7273H34.5455C37.558 7.27274 40 9.71483 40 12.7273V16.3637C40 17.3678 39.186 18.1818 38.1819 18.1818C37.1777 18.1818 36.3637 17.3678 36.3637 16.3637V12.7273C36.3637 11.7231 35.5497 10.9091 34.5455 10.9091H22.7273H19.6015H5.45455C4.45041 10.9091 3.63637 11.7231 3.63637 12.7273V14.5455V30.9091C3.63637 31.9133 4.45041 32.7273 5.45455 32.7273H14.5455C15.5496 32.7273 16.3637 33.5413 16.3637 34.5455C16.3637 35.5497 15.5496 36.3637 14.5455 36.3637H5.45455C2.44209 36.3637 0 33.9217 0 30.9091V14.5455V12.7273V5.45455ZM34.8575 30.468C35.8093 29.0309 36.3637 27.3075 36.3637 25.4546C36.3637 20.4338 32.2935 16.3637 27.2728 16.3637C22.252 16.3637 18.1818 20.4338 18.1818 25.4546C18.1818 30.4753 22.252 34.5455 27.2728 34.5455C29.1257 34.5455 30.8491 33.9911 32.2862 33.0393L36.8962 37.6493C37.6062 38.3593 38.7575 38.3593 39.4675 37.6493C40.1775 36.9393 40.1775 35.788 39.4675 35.078L34.8575 30.468ZM21.8182 25.4546C21.8182 22.442 24.2602 20 27.2728 20C30.2853 20 32.7273 22.442 32.7273 25.4546C32.7273 28.4671 30.2853 30.9091 27.2728 30.9091C24.2602 30.9091 21.8182 28.4671 21.8182 25.4546Z"
                      fill={svgFillColor(this.state.showTable)}
                    />
                  </svg>
                </Button>
              </div>

              <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                  <div style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    fontSize: '14px',
                    zIndex: 10,
                    width: 'fit-content',
                  }}>
                  </div>
                  {this.state.centerLat !== null && this.state.centerLng !== null ? (
                    <MapGoogleUI
                      mapData={this.state.fetchMapDataArray}
                      userLat={this.state.userLocation?.lat}
                      userLng={this.state.userLocation?.lng}
                      iconCategoryFunction={iconCategoryChangeFunction}
                      iconDataSet={this.state.iconData}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </Col>
          {
            this.state.showTable && (
              <div style={{ width: '100%', padding: 20, backgroundColor: 'white', borderRadius: '10px', marginTop: 20 }}>
                <span className="textStyles-small" style={{ fontSize: 16, marginTop: 10, marginLeft: 10, fontWeight: 600 }}>Site Details</span>
                <Table
                  size="small"
                  className="table-striped-rows"
                  style={{ marginTop: 20 }}
                  columns={this.tableColumns}
                  loading={this.state.loading}
                  dataSource={this.state.fetchMapDataArray}
                  pagination={true}
                />
              </div>
            )
          }
          {
            this.state.showReferences && this.state.iconCategory !== 0 ? (
              <div style={{ width: '100%', padding: 20, backgroundColor: 'white', borderRadius: '10px', marginTop: 20 }}>
                <span className="textStyles-small" style={{ fontSize: 14, marginTop: 10, fontWeight: 600 }}>Reference - {
                  switchDataMap.find((data) => data.value === this.state.iconCategory).label}</span>
                <Row style={{ marginTop: 15 }}>
                  {
                    this.state.iconCategory === 1 && this.state.iconData?.powerConnectionType?.map((colorCode, index) => (
                      <Col span={6} key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                          <div
                            dangerouslySetInnerHTML={{ __html: getModifiedSvg(colorCode.icon, 22, 31, '#BA183A') }}
                          />
                          <span className="textStyles-small" style={{ marginLeft: 5 }}>{colorCode.name ? colorCode.name : 'Others'}</span>
                        </div>
                      </Col>
                    ))
                  }
                  {
                    this.state.iconCategory === 2 && this.state.iconData?.towerCategory?.map((colorCode, index) => (
                      <Col span={6} key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                          <div
                            dangerouslySetInnerHTML={{ __html: getModifiedSvg(colorCode.icon, 22, 31, '#BA183A') }}
                          />
                          <span className="textStyles-small" style={{ marginLeft: 5 }}>{colorCode.name ? colorCode.name : 'Others'}</span>
                        </div>
                      </Col>
                    ))
                  }
                  {
                    this.state.iconCategory === 3 && this.state.iconData?.operator?.map((colorCode, index) => (
                      <Col span={6} key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                          <div
                            dangerouslySetInnerHTML={{ __html: getModifiedSvg(colorCode.icon, 22, 31, '#BA183A') }}
                          />
                          <span className="textStyles-small" style={{ marginLeft: 5 }}>{colorCode.name ? colorCode.name : 'Others'}</span>
                        </div>
                      </Col>
                    ))
                  }
                  {
                    this.state.iconCategory === 4 && this.state.iconData?.siteOwner
                      ?.filter(colorCode => towerOwner.includes(colorCode.name))
                      .map((colorCode, index) => (
                        <Col span={6} key={index}>
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                            <div
                              dangerouslySetInnerHTML={{ __html: getModifiedSvg(colorCode.icon, 22, 31, '#BA183A') }}
                            />
                            <span className="textStyles-small" style={{ marginLeft: 5 }}>{colorCode.name ? colorCode.name : 'Others'}</span>
                          </div>
                        </Col>
                      ))
                  }
                </Row>
              </div>
            ) : null
          }

          {
          this.state.showReferences ? (
                        <div style={{ width: '100%', padding: 20, backgroundColor: 'white', borderRadius: '10px', marginTop: 20 }}>
                <span className="textStyles-small" style={{ fontSize: 14, marginTop: 10, fontWeight: 600 }}>Depot Color Reference</span>
                <Row style={{ marginTop: 15 }}>
                  {
                    this.state?.currentDepots?.map((colorCode, index) => (
                      <Col span={6} key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                        <div style={{ width: 15, height: 15, backgroundColor: colorCode.color, borderRadius: '50%' }} />
                          <span className="textStyles-small" style={{ marginLeft: 5 }}>{colorCode.depotName ? colorCode.depotName : 'N/A'}</span>
                        </div>
                      </Col>
                    ))
                  }
                </Row>
              </div>
          ): null
          }
        </Spin>

        {/* FILTER SECTION  */}
        <Modal
          title={<span className="textStyles-small" style={{ fontSize: 15 }}>Filter By</span>}
          open={this.state.openFilter}
          onCancel={() => this.setState({ openFilter: false })}
          onOk={() => this.setState({ openFilter: false })}
          destroyOnClose={false}
          width={350}
          footer={null}
          header={null}
          style={{
            position: 'fixed',
            top: 20,
            right: 20,
            overflow: 'auto',
          }}
        >
          <Tabs defaultActiveKey="1" items={tabItems}
            tabBarStyle={{ width: '100%' }}
            className="custom-tabs" />
        </Modal>
      </div>
    );
  }
}

const AnyReactComponent = ({ text, onClick, children }) => (
  <div onClick={onClick} style={{ cursor: 'pointer' }}>
    {text && <div>{text}</div>}
    {children}
  </div>
);

export default MapWithCustomPins;